import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { MenuOverlay } from './menu/MenuOverlay';
import LogoFull from '../images/Tallinn_logo_RGB.svg';
import LogoMobile from '../images/Tallinn_m2rk_RGB.svg';
import BurgerIcon from '../images/burger.svg';
import CloseIcon from '../images/x_kinni.svg';
import { LocaleSwitcher } from './LocaleSwitcher';
import { LocalizedLink } from './LocalizedLink';
import { screenSize } from '../styles/ScreenSize';
import { Breadcrumbs } from './Breadcrumbs';
import { useLayoutContext } from '../contexts/LayoutContext';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocaleContext } from '../contexts/LocaleContext';
import { MobileLogosQuery } from '../generated/graphql-types';

export const HeaderWrapper = styled.header`
	font-size: 16px;
	padding: 0 var(--padding);
	display: flex;
	width: 100%;
	height: var(--nav-bar-height);
	color: #0072ce;
	background: var(--grey);
	align-items: center;
	position: fixed;
	z-index: 200;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 0.02em;

	a {
		color: inherit;
		text-decoration: none;
	}

	a:hover {
		color: var(--hover-color);
	}
`;

const LogoWrapper = styled.div`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	line-height: 0;

	svg {
		height: 26px;
	}
`;

const OnlySm = styled.span`
	display: none;

	svg {
		height: 48px;
		margin: -8px;
	}

	${screenSize.sm} {
		display: initial;
	}
`;

const OnlySmHeader = styled.span`
	display: none;

	svg {
		height: 32px;
		margin-top: 8px;
	}

	${screenSize.sm} {
		display: initial;
	}
`;
const OnlySmLocaleSwitcher = styled.span`
	display: none;

	${screenSize.sm} {
		display: initial;
	}
`;

const NotSm = styled.div`
	display: flex;
	flex-flow: row;

	${screenSize.sm} {
		display: none;
	}
`;

const FlexCenter = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`;

const Burger = styled.button`
	width: 36px;
	height: 26px;
	margin-right: 14px;
	border: none;
	background: transparent;
	padding: 4px;
	cursor: pointer;

	:hover {
		polygon,
		rect {
			fill: var(--hover-burger);
		}
	}

	${screenSize.sm} {
		height: 24px;
		margin-right: 16px;
		padding: 0;
	}

	svg {
		height: 100%;
	}
`;

const LogoTitle = styled.div`
	img {
		height: 32px;
		margin-top: 32px;
	}
`;

const logoQuery = graphql`
	query MobileLogos {
		mobileLogos: allFile(filter: { name: { glob: "tallinn_vapita_logo.*" } }) {
			nodes {
				publicURL
				base
			}
		}
	}
`;

export const Header = () => {
	const { breadcrumbs, isMenuOpen, setMenuOpen } = useLayoutContext();
	const { t } = useTranslation();

	function toggleMenu() {
		setMenuOpen(!isMenuOpen);
	}

	useEffect(() => {
		document.documentElement.style.setProperty('overflow', isMenuOpen ? 'hidden' : 'auto');
	}, [isMenuOpen]);

	const { mobileLogos } = useStaticQuery<MobileLogosQuery>(logoQuery);

	const { locale } = useLocaleContext();

	const currentLocaleLogo =
		mobileLogos.nodes.find((logoNode) => logoNode.base.includes(`.${locale}.`)) || mobileLogos.nodes[0];

	return (
		<>
			<HeaderWrapper id={'header'}>
				<FlexCenter>
					<Burger
						aria-label={isMenuOpen ? t('sulge menüü') : t('ava menüü')}
						onClick={toggleMenu}
						style={isMenuOpen ? { marginLeft: '-6px', marginRight: '22px' } : {}}
					>
						{isMenuOpen ? <CloseIcon aria-hidden="true" /> : <BurgerIcon aria-hidden="true" />}
					</Burger>
					<OnlySmHeader>
						{isMenuOpen ? (
							t('Sisukord')
						) : (
							<LocalizedLink aria-label="Tallinn 2035 Arengustrateegia" to={'/'}>
								<LogoTitle aria-hidden="true">
									{currentLocaleLogo?.publicURL && <img src={currentLocaleLogo.publicURL} />}
								</LogoTitle>
							</LocalizedLink>
						)}
					</OnlySmHeader>
					<NotSm>
						{isMenuOpen && t('Sisukord')}
						{!isMenuOpen && <Breadcrumbs crumbs={breadcrumbs} />}
					</NotSm>
				</FlexCenter>
				<NotSm>
					{/* RU is not translated yet */}
					{locale === 'et' && (
						<LocalizedLink to={'/jalgi'} onClick={() => setMenuOpen(false)}>
							{t('Jälgi elluviimist')}
						</LocalizedLink>
					)}
					{locale === 'en' && (
						<LocalizedLink to={'/follow'} onClick={() => setMenuOpen(false)}>
							{t('Jälgi elluviimist')}
						</LocalizedLink>
					)}
					<LocaleSwitcher />
				</NotSm>
				<OnlySmLocaleSwitcher>{isMenuOpen && <LocaleSwitcher />}</OnlySmLocaleSwitcher>
				<LogoWrapper>
					<NotSm>
						<LogoFull />
					</NotSm>
					<OnlySm>
						<LogoMobile />
					</OnlySm>
				</LogoWrapper>
			</HeaderWrapper>
			{isMenuOpen && <MenuOverlay />}
		</>
	);
};
