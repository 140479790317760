import * as React from 'react';
import styled from 'styled-components';

import './layout.css';
import '../font/stylesheet.css';
import { Header } from './header';
import { Footer } from './footer';
import { LayoutProvider } from '../contexts/LayoutContext';
import { SidebarProvider } from '../contexts/SidebarContext';
import { GlobalStyle } from '../styles/GlobalStyles';
import { LocaleContextProvider } from '../contexts/LocaleContext';
import { CookieNotice } from './CookieNotice';
import { useKeyboardFocus } from '../hooks/useKeyboardFocus';

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	min-height: 100vh;
	position: relative;

	main {
		display: flex;
		flex-flow: column;
		flex: 1;
	}
`;

const Content = styled.div`
	position: relative;
	margin-top: var(--nav-bar-height);
`;

const Layout: React.FC = ({ children }) => {
	useKeyboardFocus();

	return (
		<LocaleContextProvider>
			<LayoutProvider>
				<Wrapper>
					<GlobalStyle />
					<Header />
					<SidebarProvider>
						<Content>{children}</Content>
					</SidebarProvider>
					<Footer />
					<CookieNotice />
				</Wrapper>
			</LayoutProvider>
		</LocaleContextProvider>
	);
};

export default Layout;
