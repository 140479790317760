import { createGlobalStyle } from 'styled-components';
import { screenSize } from './ScreenSize';

interface GlobalStyle {}

export const GlobalStyle = createGlobalStyle<GlobalStyle>`
	
	:root {
		--primary-color: #000;
		--hover-color: #fff;
		--hover-grey: #848584;
		--hover-burger: #fff;
		--secondary-color: #0072ce;
		--grey: #e0e0e0;
		--light-grey: #f2f2f2;

		--active-color: var(--secondary-color);
		--footer-color: var(--secondary-color);
		--button-grey: var(--light-grey);
		
		--notification-background: rgba(0, 114, 206, 0.75);

		--content-width: 800px;
		--content-top-margin: 48px;
		--content-bottom-margin: 64px;

		--underline: 1px solid;
		--padding: 24px;
		--content-padding-right: 34px;
		--hero-height: 75vh;
		--hero-padding: 3%;
		--nav-bar-height: 42px;
		--footer-height: 42px;

		outline-color: #0072CE;

		${screenSize.md} {
			--padding: 16px;
			--hero-height: 65vh;
			--hero-padding: 4%;
			--content-padding-right: 16px;
		}

		${screenSize.sm} {
			--hero-height: 75vh;
			--hero-padding: 24px 16px;
			--nav-bar-height: 50px;
			--hover-burger: #0072ce;
		}
	}

	body {
		font-family: Lab Grotesque, sans-serif;
		font-weight: normal;
		font-size: 24px;
		line-height: 1.3;
		-moz-osx-font-smoothing: grayscale;

		&.using-mouse :focus {
			outline: none;
			box-shadow: none;
			border: none;
  	}

		${screenSize.sm} {
			font-size: 18px;
		}
	}
	
	a {
		font-family: Lab Grotesque, sans-serif;
		color: inherit;

		:hover {
			color: var(--hover-grey);
		}
		
		:active {
			color: var(--hover-grey);
		}
	}

	h1 {
		font-family: Lab Grotesque, sans-serif;
		font-weight: normal;
		font-size: 68px;
		line-height: 1;
		letter-spacing: -0.015em;

		${screenSize.sm} {
			font-size: 46px;
			line-height: 1.1;
		}
	}
	
	h2 {
		font-family: Lab Grotesque, sans-serif;
		font-size: 68px;
		line-height: 1;
		font-weight: normal;
		letter-spacing: -0.015em;
		
		${screenSize.sm} {
			font-size: 32px;	
			line-height: 1.1;
		}
	}

	h3 {
		font-family: Lab Grotesque, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.3;

		${screenSize.sm} {
			font-size: 18px;		
		}
	}

	p {
		margin-top: 0;
		
		:last-child {
			margin-bottom: 0;
		}
	}

	b, strong {
		font-weight: 700;
	}

	blockquote {
		margin-left: 22px;
	}
	
	ol {
		list-style: none;
		counter-reset: ol;
		font-size: 20px;
		line-height: 1.2;
		margin-top: 0;
		margin-bottom: 32px;
		padding-inline-start: 32px;


		${screenSize.sm} {
			padding-inline-start: 22px;
			font-size: 14px;
			line-height: 1.3;
		}
	}
	
	ol > li {
		counter-increment: count;
		position: relative;
		margin-bottom: 16px;

		${screenSize.sm} {
			margin-bottom: 8px;
		}
	}
	
	ol > li::before {
		content: counter(count) '.';
		color: inherit;
		position: absolute;

		left: -32px;
		${screenSize.sm} {
			left: -22px;
		}
	}
	
	ul {
		font-size: 20px;
		line-height: 1.2;
		list-style: none;

		padding-inline-start: 40px;

		${screenSize.sm} {
			font-size: 14px;
			line-height: 1.3;
			padding-inline-start: 22px;		
		}
	}
	
	ul li::before {
		content: '•';
		color: var(--active-color);
		font-weight: bold;
		display: inline-block;
		width: 22px;
		margin-left: -22px;
	}

	ol ul {
		margin-left: 22px;
		padding-inline-start: 0;
	}

	.footnote-ref {
		text-decoration: none;
		color: var(--active-color);
		font-weight: bold;
		font-size: 0.8em;
	}

	.footnote-backref {
		display: none;
	}

	.footnotes {
		hr { 
			display: none;
		}
		max-width: 800px;
    	width: 100%;
   		padding: 0 var(--padding);
		padding-bottom: 56px;

		${screenSize.sm} {
			padding-bottom: 24px;
		}

		ol li {
			${screenSize.sm} {
				font-size: 14px;
				line-height: 1.3;

				&:not(:first-of-type) {
					margin-top: 0.7rem;
				}
			}
		}

		ol li:before {
			content: counter(count) " ";
		}

		a {
			color: var(--active-color);
		}
	}
	
	.sr-only {
		position:absolute;
		left:-10000px;
		top:auto;
		width:1px;
		height:1px;
		overflow:hidden;
	}

	.mapbox-improve-map {
		display: none;
	}
`;
