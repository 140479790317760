module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#0072ce","display":"minimal-ui","icon":"src/images/Tallinn_m2rk_RGB.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"174df4501ee4d0e7f65bccf8be8e8e3d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/site/node_modules/gatsby-remark-images","id":"a1853107-5e7d-5937-838c-493b92bfa92b","name":"gatsby-remark-images","version":"6.5.2","modulePath":"/site/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"linkImagesToOriginal":false,"maxWidth":900,"wrapperStyle":"margin: 6px 6px 0 0","backgroundColor":"none"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/site/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.5.0","modulePath":"/site/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/site","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":900,"wrapperStyle":"margin: 6px 6px 0 0","backgroundColor":"none"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/site/src/components/layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-665827-31","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"redirect":false,"localeJsonSourceName":"locale","languages":["et","en","ru"],"defaultLanguage":"et","siteUrl":"https://strateegia.tallinn.ee/","generateDefaultLanguagePage":false,"i18nextOptions":{"fallbackLng":"et","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
