import styled from 'styled-components';
import React from 'react';
import { useLayoutContext } from '../contexts/LayoutContext';
import { screenSize } from '../styles/ScreenSize';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const FooterWrapper = styled.footer`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	min-height: var(--footer-height);
	padding: 0 var(--padding);
	background-color: var(--footer-color);

	font-size: 18px;
	line-height: 1.1;
	color: #ffffff;

	a {
		color: #ffffff;
		text-decoration: none;
	}

	a:hover {
		color: inherit;
	}

	${screenSize.sm} {
		padding: 8px var(--padding);

		a {
			display: block;

			:not(:first-of-type) {
				margin-top: 4px;
			}
		}
	}
`;

export const Footer = () => {
	const { isFooterHidden } = useLayoutContext();
	const { t } = useTranslation();

	return (
		<>
			{!isFooterHidden && (
				<FooterWrapper>
					<a href={'mailto:strateegia@tallinnlv.ee'}>strateegia@tallinnlv.ee</a>
					<a href="https://www.tallinn.ee/strateegia/" target="_blank">
						{t('Strateegilise planeerimise teemaleht')}
					</a>
				</FooterWrapper>
			)}
		</>
	);
};
