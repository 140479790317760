import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { Crumb } from '../components/Breadcrumbs';

interface LayoutContext {
	isMenuOpen: boolean;
	setMenuOpen: (isHidden: boolean) => void;

	isFooterHidden: boolean;
	setFooterHidden: (isHidden: boolean) => void;

	setBreadcrumbs: (crumbs: Crumb[]) => void;
	breadcrumbs: Crumb[];
}

export const LayoutContext = createContext({} as LayoutContext);

export const LayoutProvider: React.FC = ({ children }) => {
	const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
	const [breadcrumbs, setBreadcrumbs] = useState<Crumb[]>([]);
	const [isFooterHidden, setFooterHidden] = useState<boolean>(false);

	return (
		<LayoutContext.Provider
			value={{ isMenuOpen, setMenuOpen, breadcrumbs, setBreadcrumbs, isFooterHidden, setFooterHidden }}
		>
			{children}
		</LayoutContext.Provider>
	);
};

export const useLayoutContext = () => useContext(LayoutContext);
