import * as React from 'react';
import { GatsbyLinkProps, Link } from 'gatsby';
import { useLocaleContext } from '../contexts/LocaleContext';

interface LocalizedLink {
	<T>(a: GatsbyLinkProps<T> & { raw?: boolean }): JSX.Element;
}

const localePrefixRegex = /\/([a-z]{2})(?:\/|$)/i;
const doubleSlashRegex = /\/\//gi;

export const LocalizedLink: LocalizedLink = ({ to, children, ref, raw, ...props }) => {
	const { locale, defaultLocale } = useLocaleContext();

	const linkPath = getLocalizedLink(to, locale, defaultLocale, raw);

	return (
		<Link to={linkPath} {...props}>
			{children}
		</Link>
	);
};


export function getLocalizedLink(to: string, locale: string, defaultLocale: string, raw: boolean = false) {
	let linkPath = to;

	// Check if URL begins with /:locale/
	const [_, localePrefix] = localePrefixRegex.exec(linkPath) || [null, null];

	// Mostly used for mdx content links as these should not know about locales and links
	if (!localePrefix && !raw) {
		linkPath = `/${locale}/${linkPath}/`;
	}

	const [_2, localePrefix2] = localePrefixRegex.exec(linkPath) || [null, null];
	// Hide default locale in the link
	if (localePrefix2 === defaultLocale) {
		linkPath = linkPath.replace(localePrefix2, '/');
	}

	// Cleanup whatever we manage to put together
	while (doubleSlashRegex.test(linkPath)) {
		linkPath = linkPath.replace(doubleSlashRegex, '/');
	}

	return linkPath;
}