import * as React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { getAllLocaleCodes, getDefaultLocale } from '../lib/locale';
import { useI18next } from 'gatsby-plugin-react-i18next';

type PageTranslation = Record<string, any>;

interface LocaleContext {
	locale: string;
	isDefault: boolean;
	setLocale: (locale: string) => void;
	locales: string[];
	setPageTranslations: (translations: any[]) => void;
	pageTranslations: PageTranslation[];
	defaultLocale: string;
}

export const LocaleContext = createContext({} as LocaleContext);

interface LocaleContextProviderProps {
	initialLocale?: string | null;
}

const defaultLocale = getDefaultLocale();

export const LocaleContextProvider: React.FC<LocaleContextProviderProps> = ({ children, initialLocale }) => {
	const { language: locale, changeLanguage } = useI18next();
	const [pageTranslations, setPageTranslations] = useState<PageTranslation[]>([]);

	const locales = useMemo(() => getAllLocaleCodes(), []);
	const isDefault = useMemo(() => locale === defaultLocale, [locale]);

	return (
		<LocaleContext.Provider
			value={{
				locale,
				isDefault,
				setLocale: changeLanguage,
				locales,
				setPageTranslations,
				pageTranslations,
				defaultLocale,
			}}
		>
			{children}
		</LocaleContext.Provider>
	);
};

export const useLocaleContext = () => useContext(LocaleContext);
