import localeConfig from '../../config/i18n';

export function getAllLocales() {
	return localeConfig.locales;
}

export function getAllLocaleCodes() {
	return Object.keys(localeConfig.locales);
}

export function getDefaultLocale() {
	return localeConfig.defaultLocale;
}
