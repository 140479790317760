import * as React from 'react';
import styled from 'styled-components';
import ArrowRight from '../images/nool_paremale.svg';
import { Link } from 'gatsby';
import { LocalizedLink } from './LocalizedLink';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.nav`
	display: flex;
	flex-flow: row;
	align-items: center;

	svg {
		margin: 0 8px;
		height: 10px;
	}

	ol {
		display: flex;
		flex-flow: row;
		align-items: center;
		font-size: inherit;
		margin: 0;
		padding: 0;

		li:last-of-type {
			font-weight: 400;
		}

		li:first-of-type {
			font-weight: 600;
		}

		& > li {
			margin: 0;
			list-style-type: none;
			position: static;

			&::before {
				display: none;
			}
		}
	}
`;

export interface Crumb {
	title: string;
	href: string;
}

interface Breadcrumbs {
	crumbs: Crumb[];
}

export const Breadcrumbs: React.FC<Breadcrumbs> = ({ crumbs }) => {
	const { t, i18n: {language} } = useTranslation();

	return (
		<Wrapper aria-label="breadcrumbs">
			<ol>
				<li>
					<LocalizedLink to={`/${language}`}>{t('page.title')}</LocalizedLink>
				</li>
				{crumbs.map((crumb) => (
					<li key={crumb.title}>
						<ArrowRight aria-hidden="true" />
						<LocalizedLink to={crumb.href}>{crumb.title}</LocalizedLink>
					</li>
				))}
			</ol>
		</Wrapper>
	);
};
