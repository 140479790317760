import { useEffect } from 'react';

function addUsingMouse() {
	document.body.classList.add('using-mouse');
}

function removeUsingMouse() {
	document.body.classList.remove('using-mouse');
}

export function useKeyboardFocus() {
	useEffect(() => {
		document.body.addEventListener('mousedown', addUsingMouse);
		document.body.addEventListener('keydown', removeUsingMouse);

		return () => {
			document.body.removeEventListener('mousedown', addUsingMouse);
			document.body.removeEventListener('keydown', removeUsingMouse);
		};
	}, []);
}
