import React, { FC, useState } from 'react';
import { AcceptButton, Notification } from './Notification';
import Cookies from 'universal-cookie';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const CookieNotice: FC = () => {
	const cookies = new Cookies();
	const [noticeVisible, setNoticeVisible] = useState<boolean>(!cookies.get('consentAgree'));
	const { t } = useTranslation();

	function setConsentCookie() {
		// Google Tag Manager should look for consentAgree cookie
		cookies.set('consentAgree', 'true', { path: '/' });
	}

	if (!noticeVisible) return null;

	return (
		<Notification>
			<p>
				{t(
					'Tallinna veebilehel kasutatakse küpsiseid. Küpsiste kasutamisest ja isikuandmete töötlemisest Tallinna veebis saate pikemalt lugeda.'
				)}
				<a href="https://www.tallinn.ee/Andmekaitsetingimused" target="_blank">
					{t('Andmekaitsetingimustest')}
				</a>
			</p>
			{/* . */}
			<AcceptButton
				onClick={() => {
					setNoticeVisible(false);
					setConsentCookie();
				}}
			>
				{t('Sain aru')}
			</AcceptButton>
		</Notification>
	);
};
