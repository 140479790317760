import * as React from 'react';
import styled from 'styled-components';
import { useLocaleContext } from '../contexts/LocaleContext';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from './LocalizedLink';

const Select = styled.ul`
	display: flex;
	margin: 0 12px 0 20px;
	padding: 0;
`;

const Option = styled.li`
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	padding: 0;

	::before {
		content: '';
		display: none;
	}

	& + & {
		margin-left: 9px;
	}
`;

export const LocaleSwitcher: React.FC = () => {
	const { locale, locales, pageTranslations } = useLocaleContext();
	const { t } = useTranslation();

	return (
		<Select>
			{locales.map((localeOption) => {
				if (localeOption === locale) return; // Hide active locale

				const translation = pageTranslations.find((translation) => translation.fields.locale === localeOption);
				const path = translation?.path || localeOption;

				return (
					<Option key={localeOption}>
						<LocalizedLink to={`/${path}`} replace={true} raw={true}>
							{t(`locales.${localeOption}`)}
						</LocalizedLink>
					</Option>
				);
			})}
		</Select>
	);
};
