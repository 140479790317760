import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { TopicsGrid } from './TopicsGrid';
import { MenuDomains } from './MenuDomains';
import { LocalizedLink } from '../LocalizedLink';
import { AllMenuContentQuery } from '../../generated/graphql-types';
import { screenSize } from '../../styles/ScreenSize';
import MapIcon from '../../images/map.svg';
import { useMediaLayout } from 'use-media';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { useLocaleContext } from '../../contexts/LocaleContext';
import {useTranslation} from "gatsby-plugin-react-i18next";

//<editor-fold desc="Styled Components">
const Wrapper = styled.nav`
	display: flex;
	flex-flow: column;

	a {
		color: #000000;
		text-decoration: none;
	}
`;

const MainGrid = styled.div`
	display: grid;
	display: -ms-grid;

	grid-template-columns: 1fr 3fr;
	-ms-grid-columns: 1fr 3fr;
	grid-auto-rows: 1fr;

	${screenSize.sm} {
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
		grid-auto-rows: auto;
	}
`;

const MenuRowWrapper = styled.div`
	display: flex;
	flex-flow: column;

	grid-column: 1;
	-ms-grid-column: 1;

	&:last-child {
		grid-auto-rows: 2;
		-ms-grid-row: 2;
	}

	${screenSize.sm} {
		&:last-child {
			grid-row: 3;
			-ms-grid-row: 3;
		}
	}
`;

const StyledTopicsGrid = styled(TopicsGrid)`
	grid-row: span 2;
	-ms-grid-row-span: 2;
	grid-column: 2;
	-ms-grid-column: 2;

	${screenSize.sm} {
		grid-column: 1;
		-ms-grid-column: 1;
		-ms-grid-row: 2;
		grid-row: span 1;
		-ms-grid-row-span: 1;

		a {
			width: calc(100% / 2);
		}
	}
`;

interface MenuRowProps {
	slug?: string;
	color?: string;
}

const MenuRow = styled(LocalizedLink)<MenuRowProps>`
	background-color: ${(p) => p.color};
	display: flex;
	padding: var(--padding);
	position: relative;
	flex: 1;

	img {
		padding-top: 32px;
		padding-left: 6px;
		margin: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&:hover,
	&:focus {
		background-color: var(--active-color);
		color: #fff;
		outline: none;
	}

	&:hover svg path {
		stroke: #fff;
	}

	${screenSize.sm} {
		svg {
			height: 14px;
			margin: 4px;
		}

		img {
			display: none;
		}
	}

	${screenSize.md} {
		font-size: 16px;
	}
`;

const RowTitle = styled.div`
	svg {
		height: 20px;
		margin-left: 10px;
		padding-top: 2px;

		${screenSize.md} {
			padding-top: 6px;
		}
	}

	${screenSize.sm} {
		display: flex;
		align-items: center;

		svg {
			margin-top: -0.2em;
		}
	}
`;

const OnlySm = styled.span`
	display: none;

	${screenSize.sm} {
		display: initial;
	}
`;
//</editor-fold>

export const MenuContent: React.FC = () => {
	const { locale } = useLocaleContext();
	const {t} = useTranslation();

	const {
		allMenuYaml: { nodes },
		allFile: { nodes: images },
	} = useStaticQuery<AllMenuContentQuery>(graphql`
		query AllMenuContent {
			allMenuYaml {
				nodes {
					contents {
						fields {
							locale
						}
						frontmatter {
							title
							group
							background
							menuimage
							slug
						}
					}
				}
			}
			allFile(filter: { extension: { eq: "svg" } }) {
				nodes {
					name
					publicURL
				}
			}
		}
	`);

	const JALGI_ELLUVIIMIST = [
		{
			frontmatter: { background: '#ACD3EF', title: t('Jälgi elluviimist'), group: 'kava', slug: 'jalgi' },
			fields: { locale: 'et' },
		},
		{
			frontmatter: { background: '#ACD3EF', title: t('Jälgi elluviimist'), group: 'kava', slug: 'follow' },
			fields: { locale: 'en' },
		}
	];

	const menuItems = nodes
		.filter((menuNode) => menuNode.contents?.every((content) => content?.frontmatter?.group !== 'üldeesmärk'))
		.map((node) => {
			return node.contents?.find((content) => content?.fields?.locale === locale) || node.contents?.[0];
		});

	const menuItemsWithImg = menuItems.map((content) => ({
		...content,
		img: images.find((image) => image.name === content?.frontmatter?.menuimage),
	}));

	const splitAt = Math.floor(menuItemsWithImg.length / 2);

	return (
		<Wrapper>
			<MainGrid>
				<MenuRowItems items={menuItemsWithImg.slice(0, splitAt) as Row[]} />
				<StyledTopicsGrid />

				{JALGI_ELLUVIIMIST.map(row => {
					return locale === row.fields.locale && (
						<OnlySm>
							<MenuRowItems items={[row]} />
						</OnlySm>
					)
				})}

				<MenuRowItems items={menuItemsWithImg.slice(splitAt) as Row[]} />
			</MainGrid>
			<MenuDomains />
		</Wrapper>
	);
};

interface MenuRowItems {
	items: Row[];
}

interface Row {
	frontmatter: MenuObject;
	fields: { locale: string };
	img?: { publicURL: string };
}

interface MenuObject {
	background: string;
	title: string;
	group: string;
	slug: string;
}

export const MenuRowItems: React.FC<MenuRowItems> = ({ items }) => {
	const { setMenuOpen } = useLayoutContext();
	const isMobile = useMediaLayout(screenSize.sm);

	function closeMenu() {
		setMenuOpen(false);
	}

	return (
		<MenuRowWrapper>
			{items.map(({ frontmatter, img }) => (
				<MenuRow
					to={`/${frontmatter?.slug}`}
					onClick={closeMenu}
					key={frontmatter?.slug}
					color={frontmatter?.background}
				>
					<RowTitle>
						{frontmatter?.title}
						{frontmatter?.group === 'map' && isMobile && <MapIcon />}
					</RowTitle>
					{img?.publicURL && <img src={img?.publicURL} alt="" />}
				</MenuRow>
			))}
		</MenuRowWrapper>
	);
};
