import * as React from 'react';
import styled from 'styled-components';
import { MenuContent } from './MenuContent';

const Wrapper = styled.nav`
	position: fixed;
	top: var(--nav-bar-height);
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: auto;
	z-index: 100;
	background-color: #f2f2f2;

	a {
		color: #000000;
		text-decoration: none;
	}
`;

export const MenuOverlay: React.FC = () => {
	return (
		<Wrapper>
			<MenuContent />
		</Wrapper>
	);
};
