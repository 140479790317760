import * as React from 'react';
import { CSSProperties } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { LocalizedLink } from '../LocalizedLink';
import { screenSize } from '../../styles/ScreenSize';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { AllTopicsInGridQuery } from '../../generated/graphql-types';
import { useI18next } from 'gatsby-plugin-react-i18next';

//<editor-fold desc="Styled Components">
const MenuItem = styled(LocalizedLink)`
	padding: var(--padding);
	background-color: ${(p) => p.theme.bgColor};
	background-image: url(${(p) => p.theme.bgImgUrl});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom;

	&:hover,
	&:focus {
		background-color: ${(p) => p.theme.bgHover};
		color: var(--hover-color);
		outline: none;
	}

	&:after {
		content: '';
		display: block;
		padding-bottom: 82%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	a {
		width: calc(100% / 3);
		color: #000000;
		text-decoration: none;
	}
`;

const ItemGroup = styled.div`
	text-transform: uppercase;
	font-size: 10px;
	line-height: 2;
	letter-spacing: 0.1em;

	${screenSize.md} {
		font-size: 8px;
	}
`;

const ItemTitle = styled.div`
	${screenSize.md} {
		font-size: 16px;
	}
`;
//</editor-fold>

interface TopicsGrid {
	className?: string;
	style?: CSSProperties;
}

export const TopicsGrid: React.FC<TopicsGrid> = ({ className, style }) => {
	const { setMenuOpen } = useLayoutContext();
	const { language: locale } = useI18next();

	function closeMenu() {
		setMenuOpen(false);
	}

	const {
		allTopicsYaml: { nodes: topics },
		allFile: { nodes: images },
	} = useStaticQuery<AllTopicsInGridQuery>(graphql`
		query AllTopicsInGrid {
			allTopicsYaml {
				nodes {
					contents {
						fields {
							locale
						}
						frontmatter {
							slug
							running_title
							title
							group
							background
							hover
							menuimage
						}
					}
				}
			}
			allFile(filter: { extension: { eq: "svg" } }) {
				nodes {
					name
					publicURL
				}
			}
		}
	`);

	const topicsWithImg = topics.map(({ contents }) => {
		const content = contents?.find((content) => content?.fields?.locale === locale);
		return {
			content,
			img: images.find((image: { name: string }) => image.name === content?.frontmatter?.menuimage),
		};
	});

	return (
		<Wrapper className={className} style={style}>
			{topicsWithImg.map((topic) => {
				const frontmatter = topic.content?.frontmatter;

				const theme = {
					bgColor: frontmatter?.background,
					bgHover: frontmatter?.hover,
					bgImgUrl: topic.img?.publicURL,
				};

				return (
					<MenuItem theme={theme} key={frontmatter?.slug} to={`/${frontmatter?.slug}`} onClick={closeMenu}>
						<ItemGroup>{frontmatter?.running_title || ''}</ItemGroup>
						<ItemTitle>{frontmatter?.title || ''}</ItemTitle>
					</MenuItem>
				);
			})}
		</Wrapper>
	);
};
