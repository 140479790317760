import React from 'react';
import styled from 'styled-components';
import { screenSize } from '../styles/ScreenSize';

export const Notification = styled.div`
	position: fixed;
	bottom: 0;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100vw;
	padding: 10px var(--padding);
	background-color: var(--notification-background);

	color: white;
	font-size: 0.9rem;

	p {
		margin: 0;
	}

	${screenSize.sm} {
		flex-direction: column;
		align-items: flex-start;
	}

	a:hover {
		color: var(--grey);
	}
`;

export const AcceptButton = styled.button`
	display: flex;
	padding: 3px 10px;
	margin-left: 48px;
	background-color: white;

	border: none;
	border-radius: 5px;

	color: #0072ce;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.4;
	letter-spacing: 0.02em;
	white-space: nowrap;

	cursor: pointer;

	:hover,
	:focus {
		background-color: #0072ce;
		color: #fff;
	}

	${screenSize.sm} {
		margin: var(--padding) 0 32px 0;
	}
`;
