import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { LocalizedLink } from '../LocalizedLink';
import { useLocaleContext } from '../../contexts/LocaleContext';
import { screenSize } from '../../styles/ScreenSize';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { AllMenuDomainsQuery } from '../../generated/graphql-types';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	padding-bottom: 48px;
	background-color: #f2f2f2;
	width: 100%;

	&& h2 {
		color: #000;
		display: block;
		margin: var(--padding) var(--padding) calc(var(--padding) - 8px) var(--padding);
		font-weight: bold;
		font-size: 24px;
		line-height: 34px;

		${screenSize.sm} {
			font-size: 18px;
		}
	}

	${screenSize.md} {
		font-size: 16px;
	}

	${screenSize.sm} {
		font-size: 14px;
	}
`;

const Grid = styled.div`
	width: 100%;
	column-count: 4;
	column-gap: 20px;
	display: block;

	${screenSize.sm} {
		column-count: 2;
		column-gap: 20px;
		display: block;
	}
`;

const StyledLink = styled(LocalizedLink)`
	margin: 0 var(--padding) 0;
	display: block;

	&& {
		color: #0072ce;
		text-decoration: underline;
		text-underline-offset: 0.2em;
		text-decoration-thickness: 1px;
		padding-top: 16px;
		padding-left: 4px;
	}

	:hover {
		color: var(--hover-grey);
	}
`;

export const MenuDomains: React.FC = () => {
	const localeContext = useLocaleContext();
	const { setMenuOpen } = useLayoutContext();
	const { t } = useTranslation();

	function closeMenu() {
		setMenuOpen(false);
	}

	const {
		allMdx: { nodes: domains },
	} = useStaticQuery<AllMenuDomainsQuery>(graphql`
		query AllMenuDomains {
			allMdx(filter: { frontmatter: { group: { eq: "valdkond" }, title: {} } }, sort: { fields: frontmatter___title }) {
				nodes {
					fields {
						locale
					}
					frontmatter {
						slug
						title
					}
				}
			}
		}
	`);

	const domainsByLocale = domains.filter((domain) => domain.fields?.locale === localeContext.locale);

	return (
		<Wrapper>
			<h2>{t('Tegevusvaldkonnad')}</h2>

			<Grid>
				{domainsByLocale.map(({ frontmatter }) => {
					if (!frontmatter) {
						console.warn('Missing frontmatter in domains');
						return null;
					}

					return (
						<StyledLink to={`/${frontmatter.slug}`} key={frontmatter.slug} onClick={closeMenu}>
							<span>{frontmatter.title}</span>
						</StyledLink>
					);
				})}
			</Grid>
		</Wrapper>
	);
};
