module.exports = {
	defaultLocale: 'et',

	locales: {
		et: {
			path: 'et',
		},
		en: {
			path: 'en',
		},
		ru: {
			path: 'ru',
		},
	}
};
