import * as React from 'react';
import { createContext, useContext, useState } from 'react';

interface SidebarContext {
	currentElement: JSX.Element | null;
	setCurrentElement: (element: JSX.Element) => void;
}

export const SidebarContext = createContext({} as SidebarContext);

export const SidebarProvider: React.FC = ({ children }) => {
	const [currentElement, setCurrentElement] = useState<JSX.Element | null>(null);

	return <SidebarContext.Provider value={{ currentElement, setCurrentElement }}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => useContext(SidebarContext);
